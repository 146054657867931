import * as React from "react";
import * as ReactDOM from "react-dom";
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import {applyTransform} from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import Draw, {createRegularPolygon, createBox, DrawEvent} from 'ol/interaction/Draw';
import {get as getProjection, getTransform} from 'ol/proj';
import {register} from 'ol/proj/proj4';
import OSM from 'ol/source/OSM';
import proj4 from 'proj4';
import { Vector as VectorSource, GeoJSON, TileWMS} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import { OutputProjectionType, Roi } from "../../../../lambda/interfaces/rois";
import {defaults as defaultControls, Control} from 'ol/control';
import { Point, LinearRing, Polygon} from 'ol/geom';
import LayerGroup from 'ol/layer/Group';
import { Util } from "../../util/Util";

let baseUrl: string;
if (window.location.host.startsWith("localhost")) {
  baseUrl = 'http://127.0.0.1:8085/cgi-bin/mapserv?map=';
}
else if (window.location.host === "development.shellmetnetspatial.com") {
    baseUrl = 'https://mapserver.development.shellmetnetspatial.com/cgi-bin/mapserv?map=';
} else {
    baseUrl = 'https://mapserver.shellmetnetspatial.com/cgi-bin/mapserv?map=';
}

var source = new VectorSource({wrapX: false});

var raster = new TileLayer({
  source: new OSM()
});

var vector = new VectorLayer({
  source: source
});

var equiRectExtent = [-180.0, -85.06, 180.0, 85.06];

function formatNumber(num: number): number{
  return parseFloat(Util.formatNumber(num));
}

  interface Props {
    mapFilePath: string;
    roi: Roi;
  }

  interface State {
      map: Map ;
      mapDiv: any;
      mapFilePath: string;
      utmExtent: number[];
      heatMapLayer: TileLayer;
  }

const defaultProjection = 'EPSG:3857';
const equirectangularProj = 'EPSG:4326';

export class VisualizationMap extends React.Component<Props,State> {
  constructor(props) {
    super(props);

    var map: Map = undefined;
    var heatMap =  new TileLayer({  
      source: new TileWMS({
        url: 'https://mapserver.dev2.shellmetnetspatial.com/cgi-bin/mapserv?map=' + this.props.mapFilePath +'&',
        params: {'LAYERS': 'data_layer',  'FORMAT': 'image/png', 'TRANSPARENT' : 'true' },
        serverType: 'mapserver'
      })
    });

    if(this.props.roi.spatialConstraint.projectionType === OutputProjectionType.EQUIRECTANGULAR){
      //if the projection is equirectangular, the map can be set up(no need for additional information)
      heatMap.setExtent(equiRectExtent);
     
       map = new Map({
          layers: [raster, heatMap],
          target: 'map',
          view: new View({       
            projection: equirectangularProj,
            center: [0,0], 
            extent: equiRectExtent,   
            zoom: 0      
          })
        }); 
    }
    
    this.state={
        map: map,
        mapDiv: undefined,
        mapFilePath: this.props.mapFilePath,
        utmExtent: undefined,
        heatMapLayer: heatMap,
    }
  }

  componentDidMount(){
    if(this.props.roi.spatialConstraint.projectionType === OutputProjectionType.UTM){
      //if the projection type is UTM, we need to get the projection object and extent
      let identifier = Util.getUTMProjectionIdentifierForFetch(this.props.roi);

     let visualizationMap = this;

      fetch('https://epsg.io/?format=json&q=' + identifier).then(function(response) {
        return response.json();
        }).then(function(json) {
        var results = json['results'];
        if (results && results.length > 0) {
            var result = results[results.length - 1];
            if (result) {
  
                var code = result['code'];
                var proj4def = result['proj4'];
                var bbox = result['bbox'];
                
                if (code && code.length > 0 && proj4def && proj4def.length > 0 &&
                    bbox && bbox.length == 4) {                   
  
                    var newProjCode = 'EPSG:' + code;
                    proj4.defs(newProjCode, proj4def);
                    register(proj4);
                    var newProj = getProjection(newProjCode);
                    var fromLonLat = getTransform('EPSG:4326', newProj);
                
                    // very approximate calculation of projection extent
                    var extent = applyTransform(
                    [bbox[1], bbox[2], bbox[3], bbox[0]], fromLonLat);      
                    
                    newProj.setExtent(extent);
                    var newView = new View({
                      projection: newProj
                    });
                                   
                     //create the layer, that will be drawn on the map
                    var heatMap: TileLayer =  Object.assign(visualizationMap.state.heatMapLayer);
                    heatMap.setExtent(extent);
                                  
                    //create the map
                    var map: Map = new Map({
                        layers: [raster, heatMap],
                        target: 'map',          
                      }); 

                    //applying the new extent
                    map.setView(newView);                 
                    newView.fit(extent);
                    
                    visualizationMap.setState({map: map, utmExtent: extent});
                }
            }
            
        }
        });
    }    
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
      if(nextProps.mapFilePath !== prevState.mapFilePath){
        //when different map is selected, the layer on the map needs to be redrawn
        var newHeatMap =  new TileLayer({       
          source: new TileWMS({
            url: baseUrl + nextProps.mapFilePath +'&',
            params: {'LAYERS': 'data_layer',  'FORMAT': 'image/png', 'TRANSPARENT' : 'true' },
            serverType: 'mapserver'
          })
        });

        newHeatMap.setExtent(nextProps.roi.spatialConstraint.projectionType === OutputProjectionType.EQUIRECTANGULAR ? equiRectExtent : prevState.utmExtent);
       
        //copy the map
        var newMap: Map = Object.assign(prevState.map);
        let layerGroup = new LayerGroup({
          layers: [raster, newHeatMap]
        });
        newMap.setLayerGroup(layerGroup);
        
          return {
            mapFilePath: nextProps.mapFilePath,
            map: newMap,
            heatMapLayer: newHeatMap,
          }
      }

      return null;
  }

  render(){

    return (
        <div id={"map"} style={{width: '100%', height: '100%'}} className="map" ref={(el)=> {
          if(this.state.map){
            this.state.map.setTarget(el);
          }
        }}></div>
    );
  }
}