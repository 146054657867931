import * as React from "react";
import { Button, Navbar, Image, Tabs, Tab } from 'react-bootstrap';
import { InlineRoi } from '../components'
import { Roi, RoiApprovalStatus, RoiUserAccessStatus } from "../../../lambda/interfaces/rois";;
import { defaultMargin, defaultPadding, tableCaption, row, fullHeight } from "../styles/styles";
import { Link } from 'react-router-dom';
import { DataProvider, RequestStatus } from "../data/DataProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faQuestion, faPlus } from '@fortawesome/free-solid-svg-icons';
import RoiFilter from "../components/roi/RoiFilter";
import RoisMap from "./RoisMap";
import { ServerStatusIndicator } from "../components/server/ServerStatusIndicator";

export interface Props {
    isAdmin: boolean;
    dataProvider: DataProvider;
    onLogoutUser: () => void;
}

export interface State {
    approvalStatusFilter: RoiApprovalStatus | undefined;
    textFilter: string | undefined;
    filteredList: Roi[] | null;
    selectedTab: string;
}

enum TabKeys{
    TABLE = "table",
    MAP = "map",
}

export default class Home extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            approvalStatusFilter: undefined,
            textFilter: undefined,
            filteredList: null,
            selectedTab: TabKeys.TABLE,
        }     
    }

    public render(): JSX.Element
    {

        let list: Roi[] = this.state.filteredList;
        if(this.state.filteredList === null){
            list = this.props.dataProvider.getRois().filter(x => x.isOwner);
        }
        
        return (
            <div style={{...fullHeight, display: 'flex', flexFlow: 'column'}}>
            <Navbar className="shell-navbar justify-content-between" expand="lg" >  
            <div style={{...row, alignItems: 'center'}}>
                <Image src={require("../assets/shell-icon.png")} className="shell-logo" fluid />
                <div className="brand" >SHELL METNET SPATIAL</div>
            </div>   
            <div style={{...row}}>
                {
                    this.props.dataProvider.getDataLoadedStatus() == RequestStatus.SUCCESS &&
                    <Link to="/newROI" >
                        <Button className="shell-yellow-btn"
                            style={{ ...row, alignItems: 'center', marginRight: defaultMargin}}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{fontSize: 15}} className="shell-yellow-btn-icon"/>
                            <div style={{marginLeft: 5}}>Add new ROI</div>
                        </Button>
                    </Link>    
                }
                <Button 
                    className="shell-yellow-btn"
                    style={{ ...row, alignItems: 'center', marginRight: defaultMargin}}
                    onClick={() => this.props.dataProvider.refresh()}
                >
                    <FontAwesomeIcon icon={faSync} style={{fontSize: 15}} className="shell-yellow-btn-icon"/>
                    <div style={{marginLeft: 5}}>Refresh</div>
                </Button> 
                <Button 
                    className="shell-yellow-btn"
                    style={{ ...row, alignItems: 'center', marginRight: defaultMargin}}
                    onClick={() => { window.open('/support/index.html'); }}
                >
                    <FontAwesomeIcon icon={faQuestion} style={{fontSize: 15}} className="shell-yellow-btn-icon"/>
                    <div style={{marginLeft: 5}}>Help</div>
                </Button> 
                <Link to="/login">
                    <Button className="shell-yellow-btn">Log out</Button>  
                </Link>    
            </div>                                  
            </Navbar>
            <div style={{...row, justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                <div style={{marginLeft: defaultMargin, marginRight: 'auto', marginTop: defaultMargin, marginBottom: defaultMargin}}>

                    {
                        this.props.dataProvider.getDataLoadedStatus() == RequestStatus.SUCCESS && this.props.dataProvider.getRois().length > 0 &&           
                        <RoiFilter isAdmin={this.props.isAdmin} dataProvider={this.props.dataProvider} onFilter={(f) => this.setState({filteredList: f})} />
                    } 
                </div>
                {
                    this.props.dataProvider.getServerStatus() !== null && 
                    <ServerStatusIndicator serverStatus={this.props.dataProvider.getServerStatus()} />  
                }       
                { this.props.dataProvider.getDataLoadedStatus() == RequestStatus.SUCCESS &&
                    <Tabs activeKey={this.state.selectedTab} id="view-tabs" style={{width: 200, marginRight: defaultMargin, height: 50}} onSelect={(key) => this.setState({selectedTab: key})} >
                        <Tab eventKey="table" title="Table" />                
                        <Tab eventKey="map"  title="Map" />                                     
                </Tabs>
                }
                
                {
                    /*this.props.dataProvider.getDataLoadedStatus() == RequestStatus.SUCCESS &&
                    <Link to="/roisMap">
                        <Button style={{margin: defaultMargin}} className="shell-gray-btn">All ROIs map</Button>
                    </Link>  */  
                }
            </div>                         
            {
                 this.props.dataProvider.getDataLoadedStatus() == RequestStatus.SUCCESS && this.props.dataProvider.getRois().length == 0 &&
                <div style={{ marginLeft: defaultMargin * 2}}>No Region Of Interest was created yet</div>
            }
            {
                this.props.dataProvider.getDataLoadedStatus() == RequestStatus.ERROR &&
                <div style={{ marginLeft: defaultMargin * 2}}>Data could not be loaded</div>
            }
            {
                this.state.selectedTab === TabKeys.TABLE &&
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'hidden', flexFlow: 'column'}}>
                    {
                        this.props.dataProvider.getRois().length > 0 &&
                        <div style={{display: 'flex', flexDirection: 'row', margin: defaultMargin, paddingLeft: defaultPadding, paddingRight: defaultPadding}}>
                            {
                                this.props.isAdmin &&
                                <div style={{flex: 2, ...tableCaption}}>Owner</div>
                            }               
                            <div style={{flex: 2, ...tableCaption}}>Name</div>
                            <div style={{flex: 1, ...tableCaption}}> Data sources</div>
                            <div style={{flex: 1, ...tableCaption}}>Storage</div>
                            <div style={{flex: 2, ...tableCaption}}>Downloaded data availability</div>
                            <div style={{flex: 2, ...tableCaption}}>Data collection</div>
                            <div style={{flex: 2, ...tableCaption}}>Approval status</div>
                            <div style={{flex: 2, ...tableCaption}}>Access status</div>
                            <div style={{width: 30}}></div>
                        </div>

                    }   
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'scroll'}}>
                        {
                            list.map(roi =>   {
                                if(!this.props.dataProvider.doesRoiHaveUnrecognizedDataSource(roi.id)){
                                    return <InlineRoi key={roi.id}  roi={roi} isAdmin={this.props.isAdmin} dataProvider={this.props.dataProvider}/>
                                }else{
                                    return null;
                                }
                            })
                        }              
                    </div>         
                </div>
            }
               {
                   this.state.selectedTab === TabKeys.MAP &&
                   <RoisMap isAdmin={this.props.isAdmin} dataProvider={this.props.dataProvider} rois={list} />
               }        
            </div>
        );
    }
}