import * as React from "react";
import { Roi, DataCollectionLog } from "../../../../lambda/interfaces/rois";
import { row, defaultMargin, smallFontSize, shellDarkGray, lightGray,  } from "../../styles/styles";
import { Spinner, Button } from "react-bootstrap";
import { Util } from "../../util/Util";
import DatePicker  from 'react-datepicker';
import { DataProvider, RequestStatus } from "../../data/DataProvider";

export interface Props {
    roi: Roi;
    dataProvider: DataProvider;
}

export interface State {
    fetchStatus: RequestStatus;
    selectedDate: number | undefined;
    logsNextToken: string | undefined;
    logs: DataCollectionLog[];
}

export default class Logs extends React.Component<Props, State>
{
    private reqlimit = 1000;
    constructor(props: Props){
        super(props);
        let now = new Date();

        this.state = {
            fetchStatus: RequestStatus.IN_PROGRESS,
            selectedDate: Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0),
            logsNextToken: undefined,
            logs: []
        }
    }

    async componentDidMount() {
        await this.getLogs();
    }

    async getLogs(){
        this.setState({
            fetchStatus: RequestStatus.IN_PROGRESS
        });

        let resp = await this.props.dataProvider.fetchLogs(this.props.roi.id, this.state.selectedDate, this.reqlimit, this.state.logsNextToken);

        let logs: DataCollectionLog[] = [];

        if(this.state.logsNextToken){
            logs = this.state.logs.concat(resp.logs);
        }
        else {
            logs = resp.logs;
        }

        this.setState({
            logs,
            logsNextToken: resp.nextToken ? resp.nextToken : undefined,
            fetchStatus: RequestStatus.SUCCESS
        });
    }

    public render(): JSX.Element
    {
        return <div style={{color: shellDarkGray, marginBottom: "1em"}}>
            <div style={{...row, alignItems: 'center'}}>
                    <span style={{marginRight: "1em"}}>Date:</span>
                    <DatePicker
                        onChange={(date: Date) => { 
                            this.setState({
                                selectedDate: date.getTime(),
                                logsNextToken: undefined,
                                logs: []
                            }, async () => {
                                await this.getLogs();
                            });
                        }}              
                        onMonthChange={(date: Date) => { 
                            this.setState({
                                selectedDate: date.getTime(),
                                logsNextToken: undefined,
                                logs: []
                            }, async () => {
                                await this.getLogs();
                            });
                        }} 
                        onYearChange={(date: Date) => {
                            this.setState({
                                selectedDate: date.getTime(),
                                logsNextToken: undefined,
                                logs: []
                            }, async () => {
                                await this.getLogs();
                            });
                        }}         
                        dateFormat={"yyyy-MM-dd"}
                        selected={this.state.selectedDate ? new Date(this.state.selectedDate) : null}
                        showYearDropdown
                        showMonthDropdown
                        onChangeRaw={(e) => {e.preventDefault()}}
                        disabled={this.state.fetchStatus == RequestStatus.IN_PROGRESS}                                           
                    />
                </div>          
                {
                    this.state.selectedDate !== undefined && (this.state.fetchStatus == RequestStatus.IN_PROGRESS) &&               
                    <div style={{...row, alignItems: 'center', marginTop: defaultMargin}}>
                            <Spinner animation="border" role="status" color={shellDarkGray}/>
                        <div style={{ marginLeft: defaultMargin}}>Loading logs ...</div>
                    </div>
                }          
                {
                    this.state.fetchStatus == RequestStatus.ERROR &&
                    <div>There was an error while requesting the logs.</div>
                }  
                {
                    this.state.fetchStatus === RequestStatus.SUCCESS && this.state.logs.length == 0 &&
                    <div>No logs available for this date.</div>
                }
                {
                    this.state.logs.length > 0 && <table style={{marginTop: "1em"}}>
                    {
                        this.state.logs.map((log, i) => {             
                            return <tr key={"log_" + i} className="bottom-light-border" >
                                    <td style={{marginRight: defaultMargin, fontSize: smallFontSize,}}>{Util.formatTimestamp(new Date(log.timestamp).getTime())}</td>
                                    <td style={{fontSize: smallFontSize, }}>{log.message}</td>
                                </tr>
                        })
                    }
                    </table>
                }
                {
                    this.state.logsNextToken && <Button className="shell-yellow-btn btn btn-primary" style={{marginTop: "1em"}} onClick={async () => {
                        if(this.state.fetchStatus === RequestStatus.SUCCESS){
                            //don't allow us to fire off another request if we are still running the current request!
                            await this.getLogs();
                        }
                    }}>
                        {this.state.fetchStatus === RequestStatus.SUCCESS ? <>Load more</> : <>Loading, please wait ...</>}
                    </Button>
                }
        </div>  
    }
}