import * as React from "react";
import { Redirect } from 'react-router-dom';

import "../styles/styles.css";
import { defaultPadding, defaultMargin, tableCaption } from "../styles/styles";
import { Roi } from "../../../lambda/interfaces/rois";
import {  InlineRoi } from "../components";
import { DataProvider } from "../data/DataProvider";
import {  AllRoiMap } from "../components/maps/AllRoiMap";

export interface Props {
    dataProvider: DataProvider;
    isAdmin: boolean;
    rois: Roi[];
}

export interface State {
    hoveredRoiId: string | null;
    showInstructionTooltip: boolean;
    redirect: string | null;
}

export default class RoisMap extends React.Component<Props, State>
{
    constructor(props: Props){
        super(props);
        this.state = {
            hoveredRoiId: null,
            showInstructionTooltip: true,
            redirect: null,
        }
    }

    public render(): JSX.Element
    {
        if(this.state.redirect !== null){
            return <Redirect to={this.state.redirect} />;
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'hidden', flexFlow: 'column', padding: defaultPadding}}>              
                    <div style={{width: '100%'}}>                     
                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: defaultMargin * 2, }}>
                                        
                            <div style={{flex: 2, ...tableCaption}}>Name</div>
                            <div style={{flex: 1, ...tableCaption}}>Data sources</div>
                            <div style={{flex: 1, ...tableCaption}}>Storage</div>
                            <div style={{flex: 2, ...tableCaption}}>Downloaded data availability</div>
                            <div style={{flex: 2, ...tableCaption}}>Data collection</div>
                            <div style={{flex: 2, ...tableCaption}}>Approval status</div>
                            <div style={{flex: 2, ...tableCaption}}>Access status</div>
                            <div style={{width: 30}}></div>
                        </div>                                           
                        {
                            this.state.hoveredRoiId !== null && !this.props.dataProvider.doesRoiHaveUnrecognizedDataSource(this.state.hoveredRoiId) &&
                            <InlineRoi isAdmin={false} roi={this.props.dataProvider.getRoiById(this.state.hoveredRoiId)} dataProvider={this.props.dataProvider} />
                        }
                        {
                            this.state.hoveredRoiId !== null && this.props.dataProvider.doesRoiHaveUnrecognizedDataSource(this.state.hoveredRoiId) &&
                            <div style={{height: 55, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',  marginTop: defaultMargin, marginBottom: defaultMargin }} className="bottom-border-gray">This ROI can't be loaded. It has an unrecognized data source.</div>
                        }
                        {
                            this.state.hoveredRoiId == null &&
                            <div style={{height: 55, width: '100%',  marginTop: defaultMargin, marginBottom: defaultMargin, justifyContent: 'center', alignItems: 'center', display: 'flex'}} className="bottom-border-gray" >Hover the ROI rectangle boundaries to see its details.</div>
                        }
                    </div>            
                      
                        <AllRoiMap 
                            rois={this.props.rois} 
                            onRoiHover={(id) => {
                                                if(this.state.hoveredRoiId !== id){
                                                    this.setState({hoveredRoiId: id});
                                                }
                                                }}
                            onRoiClick={(id) => this.setState({redirect: '/roi/' + id})}
                                            />                                                                    
            </div>            
        );
    }
}