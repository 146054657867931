import * as React from "react";
import { Button, Tab, Tabs, Navbar, Image } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowLeft, faQuestion } from '@fortawesome/free-solid-svg-icons';

import "../styles/styles.css";
import { defaultPadding, fullHeight, row, defaultMargin, tabPage, shellDarkGray } from "../styles/styles";
import { DataIssues, DataParams, DatasetParameter, Roi, RoiApprovalStatus } from "../../../lambda/interfaces/rois";
import { DeleteRoiModal } from "../components";
import Overview from "./roi-details/Overview";
import DataCollection from "./roi-details/DataCollection";
import Visualization from "./roi-details/Visualization";
import Logs from "./roi-details/Logs";
import { DataProvider, RequestStatus } from "../data/DataProvider";
import { RoiApprovalModal } from "../components/modals/RoiApprovalModal";
import { Util } from "../util/Util";
import PermissionsTab from "./roi-details/PermissionsTab";

export interface Props {
    roi: Roi;
    dataProvider: DataProvider;
    isAdmin: boolean;
}

export interface State {  
    deleteRoiModalVisible: boolean;
    modifyApprovalStatusModalVisible: boolean;
    selectedTabKey: string;

    dataIssues: DataIssues;
    selectedIssueTimestamp: number;
    dataSetFetchStatus: RequestStatus;
    selectedYearUtc: number;
    selectedMonthUtc: number;
    dataParams: DataParams;
    selectedParam: string | null;
    datasetParameter: DatasetParameter;
    selectedRecordTimestamp: number;
}

export default class RoiDetails extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);  
        
        this.state = {
            dataIssues: null,
            selectedIssueTimestamp: null,
            dataSetFetchStatus: RequestStatus.NOT_STARTED,
            selectedYearUtc: new Date().getUTCFullYear(),
            selectedMonthUtc: new Date().getUTCMonth(),
            dataParams: null,
            selectedParam: null,
            datasetParameter: null,
            selectedRecordTimestamp: null,

            deleteRoiModalVisible: false,
            modifyApprovalStatusModalVisible: false,
            selectedTabKey: 'overview',
        } 
    }

    public async updateDataIssues() {
        this.setState({
            dataSetFetchStatus: RequestStatus.IN_PROGRESS
        });

        let dataIssues: DataIssues;
        let dataSetFetchStatus: RequestStatus;
        if (this.canRead) {
            dataIssues = await this.props.dataProvider.fetchDataSetIssuesByRoi(this.props.roi.id,
                Date.UTC(this.state.selectedYearUtc, this.state.selectedMonthUtc, 1),
                Date.UTC(this.state.selectedYearUtc, this.state.selectedMonthUtc + 1, 1));
            dataSetFetchStatus = this.props.dataProvider.getDataSetFetchStatus();
        }
        else {
            dataIssues = null;
            dataSetFetchStatus = RequestStatus.SUCCESS;
        }

        this.setState({
            dataIssues,
            selectedIssueTimestamp: dataIssues && dataIssues.issues.length ? dataIssues.issues[0].timestamp : null,
            dataSetFetchStatus
        });
    }

    public async updateDataParams() {
        this.setState({
            dataSetFetchStatus: RequestStatus.IN_PROGRESS
        });

        let dataParams: DataParams;
        let dataSetFetchStatus: RequestStatus;
        if (this.canRead) {
            dataParams = await this.props.dataProvider.fetchDataSetParamsByRoi(this.props.roi.id, this.state.selectedIssueTimestamp);
            dataSetFetchStatus = this.props.dataProvider.getDataSetFetchStatus();
        }
        else {
            dataParams = null;
            dataSetFetchStatus = RequestStatus.SUCCESS;
        }

        this.setState({
            dataParams,
            dataSetFetchStatus
        });
    }

    public async updateDatasetParams() {
        this.setState({
            dataSetFetchStatus: RequestStatus.IN_PROGRESS
        });

        let datasetParameter: DatasetParameter;
        let dataSetFetchStatus: RequestStatus;
        if (this.canRead) {
            datasetParameter = await this.props.dataProvider.fetchDataSetsByRoi(this.props.roi.id, this.state.selectedIssueTimestamp, this.state.selectedParam);
            dataSetFetchStatus = this.props.dataProvider.getDataSetFetchStatus();
        }
        else {
            datasetParameter = null;
            dataSetFetchStatus = RequestStatus.SUCCESS;
        }

        this.setState({
            datasetParameter,
            dataSetFetchStatus
        });
    }

    canRead = Util.canReadAccess(this.props.roi.userAccess);
    canUpdate = Util.canUpdateAccess(this.props.roi.userAccess);

    updateSelectedIssueTimestamp(selectedIssueTimestamp: number) {
        this.setState({
            selectedIssueTimestamp,
            selectedParam: null,
            selectedRecordTimestamp: null
        }, async () => {
            await this.updateDataParams();
        });
    }

    onDatePickerNavigate(year: number, month: number) {
        this.setState({
            selectedMonthUtc: month,
            selectedYearUtc: year,
            selectedParam: null,
            selectedRecordTimestamp: null,
            selectedIssueTimestamp: null,
            dataParams: null,
            datasetParameter: null
        }, async () => {
            await this.updateDataIssues();
        });
    }

    onDatePickerSelect() {
        this.setState({
            selectedParam: null,
            selectedRecordTimestamp: null,
            selectedIssueTimestamp: null,
            dataParams: null,
            datasetParameter: null
        });
    }

    updateSelectedParam(selectedParam: string) {
        this.setState({
            selectedParam,
            selectedRecordTimestamp: null
        }, async () => {
            await this.updateDatasetParams();
        });
    }

    onSelectRecord(selectedRecordTimestamp: number) {
        this.setState({
            selectedRecordTimestamp
        });
    }

    public render(): JSX.Element
    {
        return (
            <div style={{...fullHeight, display: 'flex', flexDirection: 'column', }}>
                <Navbar className="shell-navbar justify-content-between" expand="lg" style={{paddingTop: defaultPadding , paddingBottom: defaultPadding }} > 
                    <div style={{...row, alignItems: 'center'}}>
                        <Image src={require("../assets/shell-icon.png")} className="shell-logo" fluid />
                        <Link to="/" style={{paddingLeft: defaultPadding}}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{fontSize: 15, marginLeft: defaultMargin, color: shellDarkGray}} />
                        </Link>                      
                        <Navbar.Brand style={{color: shellDarkGray, marginLeft: defaultMargin * 2, fontWeight: 'bold'}} >{this.props.roi.name}</Navbar.Brand>                       
                    </div>                           
                    <div style={{...row}}> 
                        <Button 
                            className="shell-yellow-btn"
                            style={{ ...row, alignItems: 'center', marginRight: defaultMargin}}
                            onClick={() => { window.open('/support/index.html'); }}
                        >
                            <FontAwesomeIcon icon={faQuestion} style={{fontSize: 15}} className="shell-yellow-btn-icon"/>
                            <div style={{marginLeft: 5}}>Help</div>
                        </Button> 
                        {
                            this.props.roi.approvalStatus == RoiApprovalStatus.APPROVAL_PENDING && this.props.isAdmin &&
                            <Button 
                                style={{marginRight: defaultMargin}}
                                onClick={() => this.setState({modifyApprovalStatusModalVisible: true})}
                            >
                                Set approval status
                            </Button>     
                        } 
                        {
                            this.canUpdate && (
                                <Button 
                                    className="shell-red-btn"
                                    style={{ marginRight: defaultMargin}} 
                                    onClick={() => this.setState({deleteRoiModalVisible: true})}
                                >
                                        Delete ROI
                                </Button> 
                            )
                        }                                   
                    </div>                         
                </Navbar>  
                <div style={{...fullHeight, display: 'flex', flexDirection: 'column',}}>
                    <Tabs 
                        defaultActiveKey="overview" id="roi-details-tabs" 
                        style={{display: 'flex', backgroundColor: 'gray' }}     
                        onSelect={(key) => this.setState({selectedTabKey: key})}               
                    >
                            <Tab eventKey="overview" title="OVERVIEW" >
                                <div style={{...tabPage, ...fullHeight, }} >
                                    <Overview roi={this.props.roi} dataProvider={this.props.dataProvider} />
                                </div>                              
                            </Tab>
                            {this.canRead && (
                                <Tab eventKey="data-collection"  title="DATA DOWNLOAD">  
                                    <div style={{...tabPage, ...fullHeight, }} >
                                        <DataCollection 
                                            roi={this.props.roi} 
                                            dataProvider={this.props.dataProvider} 
                                            userCanRead={this.canRead}
                                            updateSelectedIssueTimestamp={this.updateSelectedIssueTimestamp.bind(this)}
                                            onDatePickerNavigate={this.onDatePickerNavigate.bind(this)}
                                            updateDataIssues={this.updateDataIssues.bind(this)}
                                            dataIssues={this.state.dataIssues}
                                            selectedIssueTimestamp={this.state.selectedIssueTimestamp}
                                            dataSetFetchStatus={this.state.dataSetFetchStatus}
                                            selectedYearUtc={this.state.selectedYearUtc}
                                            selectedMonthUtc={this.state.selectedMonthUtc}
                                            dataParams={this.state.dataParams}
                                            selectedParam={this.state.selectedParam}
                                            datasetParameter={this.state.datasetParameter}
                                            selectedRecordTimestamp={this.state.selectedRecordTimestamp}
                                            onDatePickerDateSelect={this.onDatePickerSelect.bind(this)}
                                            updateSelectedParam={this.updateSelectedParam.bind(this)}
                                            onSelectRecord={this.onSelectRecord.bind(this)}
                                        />
                                    </div>                                                 
                                </Tab>
                            )}
                            {this.canRead && (
                                <Tab eventKey="visualization" title="VISUALIZATION">
                                    <div style={{...tabPage, ...fullHeight, }} >
                                        <Visualization canUpdate={this.canUpdate} 
                                        currentlySelectedTab={this.state.selectedTabKey === "visualization"} 
                                        roi={this.props.roi} 
                                        dataProvider={this.props.dataProvider}
                                        updateSelectedIssueTimestamp={this.updateSelectedIssueTimestamp.bind(this)}
                                        onDatePickerNavigate={this.onDatePickerNavigate.bind(this)}
                                        updateDataIssues={this.updateDataIssues.bind(this)}
                                        dataIssues={this.state.dataIssues}
                                        selectedIssueTimestamp={this.state.selectedIssueTimestamp}
                                        dataSetFetchStatus={this.state.dataSetFetchStatus}
                                        selectedYearUtc={this.state.selectedYearUtc}
                                        selectedMonthUtc={this.state.selectedMonthUtc}
                                        dataParams={this.state.dataParams}
                                        selectedParam={this.state.selectedParam}
                                        datasetParameter={this.state.datasetParameter}
                                        selectedRecordTimestamp={this.state.selectedRecordTimestamp}
                                        onDatePickerDateSelect={this.onDatePickerSelect.bind(this)}
                                        updateSelectedParam={this.updateSelectedParam.bind(this)}
                                        onSelectRecord={this.onSelectRecord.bind(this)}
                                    />
                                    </div>   
                                </Tab>
                            )}
                            {this.canRead && (
                                <Tab eventKey="logs" title="LOGS">
                                    <div style={{...tabPage, ...fullHeight, }} >
                                        <Logs roi={this.props.roi} dataProvider={this.props.dataProvider} />
                                    </div>        
                                </Tab> 
                            )}
                            {this.canUpdate && (
                                <Tab eventKey="permissions" title="PERMISSIONS">
                                    <div style={{...tabPage, ...fullHeight, }} >
                                        <PermissionsTab roi={this.props.roi} dataProvider={this.props.dataProvider} isAdmin={this.props.isAdmin} />
                                    </div>        
                                </Tab> 
                            )}
                    </Tabs>
                </div>  
                <DeleteRoiModal 
                    dataProvider={this.props.dataProvider} 
                    roiToDelete={this.props.roi} 
                    visible={this.state.deleteRoiModalVisible} 
                    onModalClose={() => this.setState({deleteRoiModalVisible:  false})}
                    />   
                <RoiApprovalModal 
                    dataProvider={this.props.dataProvider}
                    roi={this.props.roi}
                    visible={this.state.modifyApprovalStatusModalVisible}
                    onModalClose={() => this.setState({modifyApprovalStatusModalVisible: false})}
                />                                             
            </div>            
        );
    }
}